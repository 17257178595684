/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import IllnessJPG from '@assets/images/illness.jpg';
import IllnessMobileJPG from '@assets/images/illness-mobile.jpg';
import { ContainerSmall } from '~/utils/styles/utils';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';

import { useTranslationContext } from '~/pages/demenz-alzheimer-muenchen';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/dementia-alzheimer-munich';

const DemenzPage: React.FC = () => {
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();

  return (
    <>
      <HeaderFooterLayout
        desktopHero={IllnessJPG}
        mobileHero={IllnessMobileJPG}
        altHero="Modell eines Gehirns zur Krankheitserklärung von Demenz."
        deLink="/demenz-alzheimer-muenchen/"
        enLink="/en/dementia-alzheimer-munich/"
      >
        <ContainerSmall>
          <div
            dangerouslySetInnerHTML={{
              __html: t('DementiaContent1') || tEn('DementiaContent1'),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('DementiaContent2') || tEn('DementiaContent2'),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('DementiaContent3') || tEn('DementiaContent3'),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('DementiaContent4') || tEn('DementiaContent4'),
            }}
          />
        </ContainerSmall>
      </HeaderFooterLayout>
    </>
  );
};

export default DemenzPage;
